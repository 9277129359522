.form-responses-filter--wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
}

.form-responses-filter-options {
    display: grid;
    grid-template-columns: auto auto auto;

    gap: 20px;
    padding: 0 10px;
    margin: 40px 0 30px 0;
}

.form-responses-filter-title {
    font-size: 90%;
    margin-bottom: 5px;
}

.form-responses-filter--download-all {

    margin: 66px 0 30px 0;
    height: 100%;

    /* justify-self: flex-end; */
}
.download-all-responses {
    color: rgb(42 105 206);
}