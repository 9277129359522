
.questionaire-inner {
    width: 700px;
}

.questionaire-wrapper-background {
    /* background-image: url('../../images/defaults/Elipse.png'); */
    /* background-size: 100%; */
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

}
