.desktop-nav {
    position: absolute;
    display: flex;
    width: var(--nav-width);
    margin: auto;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    padding: 20px 0;
    max-width: var(--nav-max-width);
    font-size: 110%;
}